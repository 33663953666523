<template>
  <v-img
    class="py-12"
    :src="require('@/assets/organize.png')"
  >
    <v-container>
      <h2>
        Organize and Schedule
      </h2>
      <p>
        Create Jobs on the fly and track Jobs from start to finish
      </p>
      <div>
        <v-img
          :src="require('@/assets/jobs_screen.png')"
        />
      </div>

      <h2>
        Customer Service at its best
      </h2>
      <p>
        Contractor-to-Customer messaging to keep your Customers informed; Amplify Lawns delivers text and/or email notifications to your Customers to keep everyone in the loop
      </p>
        <v-img
          :src="require('@/assets/customers_screen.png')"
        />
    </v-container>
  </v-img>
</template>

<script>
export default {};
</script>
