<template>
  <div>

    <v-app-bar absolute color="transparent" elevation="0" class="pt-5 px-0 px-md-16">
      <v-img
        :src="require('@/assets/amplify_logo.svg')"
        max-height="40"
        max-width="150"
        contain
      />

      <v-spacer></v-spacer>

      <v-btn icon dark class="d-block d-md-none" @click="drawer = !drawer">
        <v-icon>mdi-menu</v-icon>
      </v-btn>

      <v-btn
        x-large
        text
        color="white"
        class="text-capitalize d-none d-md-block"
        width="120px"
        @click="$vuetify.goTo('#features', scrollOptions)"
        >Features</v-btn
      >

      <v-btn
        x-large
        text
        color="white"
        class="text-capitalize d-none d-md-block"
        @click="$vuetify.goTo('#pricing', scrollOptions)"
        >Apply for Beta Testing</v-btn
      >
      <v-btn
      x-large
      text
      color="white"
      class="text-capitalize d-none d-md-block"
      @click="faq = true"
      >
        FAQ
      </v-btn>
      <v-btn
        x-large
        text
        color="white"
        class="text-capitalize d-none d-md-block"
        @click="about = true"
      >
          About Us
      </v-btn>
      <v-dialog
       v-model="faq"
      >
        <v-card>
          <v-card-title>
            FAQ
          </v-card-title>

          <v-card-text class="pb-0 font-weight-bold text--primary">
            How can Amplify Lawns help my lawn business?
          </v-card-text>
          <v-card-text class="font-italic text--primary">
            Amplify Lawns keeps all your information in one place, including info about each Customer, every Location (even for Customers with multiple yards), all your Jobs (all the upcoming ones plus the ability to look back through completed Jobs), and whether each Job has been Paid. Amplify Lawns also gives you the ability to easily invoice your Customer as soon as you are finished with the Job. Your Customer will get a simple link where they can pay you with a credit or debit card.
          </v-card-text>

          <v-card-text class="pb-0 font-weight-bold text--primary">
            Why is the price only $9.99 per month?
          </v-card-text>
          <v-card-text class="font-italic text--primary">
            When we first set out to build Amplify Lawns we saw that there was a hole in the market. While there were plenty of expensive apps that might be just right for larger lawn businesses and enough free/cheap apps that just did not do much, there was not much in the way of good options for smaller operators that would do the things they would need but still come at an affordable price. Amplify Lawns uses proven web technologies that have helped us to keep down development and ongoing operational costs. Additionally, Amplify Lawns is available as a Progressive Web App (PWA), which means we don't have to jack the price up just to cover the amount that the App Store or Google Play would take. All this means more bang for your buck! Sure, we need buy groceries just like you, but we don't think that means that we have to charge an unreasonable amount.
          </v-card-text>

          <v-card-text class="pb-0 font-weight-bold text--primary">
            How does Onboarding work?
          </v-card-text>
          <v-card-text class="font-italic text--primary">
            There are two main parts to Onboarding with Amplify Lawns. First, create an account and verify your email address. Second, follow the link in the app to complete the onboarding workflow with Stripe. Two notes on the Stripe onboarding: first, you can use all of the features of Amplify Lawns except invoicing your Customers even without Stripe, and second, although the Stripe onboarding does involve you providing some sensitive information (like bank account or debit card numbers), all of that sensitive information is maintained 100% by Stripe. Amplify Technologies has no way to access that information (which is just the way we like it!). Stripe has worked out the security piece on storing sensitive data. We want to let them do the part that they are best at. We will just work to help you to amplify your lawn business!
          </v-card-text>

          <v-card-text class="pb-0 font-weight-bold text--primary">
            How does the Free Trial work?
          </v-card-text>
          <v-card-text class="font-italic text--primary">
            With the 30-day Free Trial, you will have access to all of the features and benefits of Amplify Lawns.
          </v-card-text>

          <v-card-text class="pb-0 font-weight-bold text--primary">
            What about downloading reports?
          </v-card-text>
          <v-card-text class="font-italic text--primary">
            Creating and formatting reports is one of the simpler parts of the entire app design process. So we decided to wait and ask our users what reports they want and how they want them to be presented. At the moment, we have published very limited report options, but more will be coming. Just let us know what you are wanting.
          </v-card-text>

        </v-card>
      </v-dialog>
      <v-dialog v-model="about">
        <v-card 
        class="overflow-hidden"
        >
            <v-card-title>
              About Us
            </v-card-title>
            <v-card-text>
              Amplify Technologies, LLC is made up of Leon Farris, Scott Farris, and Tori Peercy. Drawing on their cumulative experiences and skills, Amplify began in 2017 in Duncan, Oklahoma with a vision to offer simple and affordable mobile apps to small businesses. Dreaming and designing, wireframing and imagining, studying and learning shaped Amplify during those first years. Now in 2023, we are so excited to launch Amplify Lawns, our first app, designed for small lawn care businesses. We truly hope this tool will help you to AMPLIFY your business profits!
            </v-card-text>
            <v-card-text>

              <div class="d-flex">
                <v-img width="200" class="mr-2" :src="require('@/assets/leon.jpeg')" />
                <div>
                  <v-card-title>Leon Farris</v-card-title>
                  <v-card-text>
                    Our Chief Executive Officer. He brings forty years of small business experience, a love for technology, and most of the resources that have made Amplify Lawns possible.
                  </v-card-text>
                </div>
              </div>
              <div class="d-flex my-6">
                <v-img width="200" class="mr-2" :src="require('@/assets/scott.jpeg')" />
                <div>
                  <v-card-title>Scott Farris</v-card-title> 
                  <v-card-text>
                    Our Chief Operations Officer. He brings experience in web programming, almost twenty years of small business experience, and a passion for helping people do more and go further.
                  </v-card-text>
                </div>
              </div>
              <div class="d-flex">
                <v-img width="200" class="mr-2" :src="require('@/assets/tori.jpeg')" />
                <div>
                  <v-card-title>Tori</v-card-title>
                  <v-card-text>
                    Our Chief Financial Officer. Tori’s organizational skills come from running her LARGE household of six children and one husband for 32 years. Those insights translate into good small business operations that shape our daily work and hone our skills for teamwork.
                  </v-card-text>
                </div>
              </div>
            </v-card-text>
              
        </v-card>
            
      </v-dialog>

      <v-btn
        x-large
        text
        dark
        class="text-capitalize d-none d-md-flex"
        href="https://amplifylawns.com/"
      >
        <v-icon class="mr-2">mdi-login</v-icon>
        Sign In</v-btn
      >
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute right>
      <v-list nav>
        <v-list-item
          link
          @click="about = true"
        >
          About
        </v-list-item>
        <v-list-item
          link
          @click="faq = true"
        >
          FAQ
        </v-list-item>
        <v-divider class="my-2" />
        <v-list-item
          @click="$vuetify.goTo('#features', scrollOptions), drawer = false"
          link
        >
          Features
        </v-list-item>
        <v-list-item
          @click="$vuetify.goTo('#pricing', scrollOptions), drawer = false"
          link
        >
          Pricing
        </v-list-item> 
        <v-divider class="my-2" />
        <v-list-item 
          href="https://amplifylawns.com/"
          link
        >
          Sign In
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>

</template>

<script>
export default {
  name: "AppBar",

  data() {
    return {
      drawer: false,
      faq: false,
      about: false,
      scrollOptions: {
        duration: 500,
        offset: 0,
        easing: "easeInOutCubic",
      },
    };
  },
};
</script>
