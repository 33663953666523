<template>
  <v-main>
    <v-img
      class="pl-0 d-flex align-center"
      :src="require('@/assets/landing.jpg')"
      max-height="700"
      min-height="600"
    >
    <!-- <v-sheet height='300' color='transparent' /> -->
        <v-container class="text-center text-md-left">
          <v-sheet color="transparent" dark>
              <div class="text-h3 text-md-h3 font-weight-bold">
                Job Management for You
              </div>

              <div class="my-3">
                No matter the business, Amplify will get you there
              </div>

              <v-btn
                light
                class="mr-5 text-capitalize"
                width="140"
                href="https://amplifylawns.com/"
              >
                Open Amplify
                <v-icon>mdi-open-in-new</v-icon>
              </v-btn>

              <v-btn
                outlined
                class="text-capitalize"
                width="140"
                @click="$vuetify.goTo('#pricing', scrollOptions)"
                >Pricing</v-btn
              >
            </v-sheet>

        </v-container>
    </v-img>
  </v-main>
</template>

<script>
export default {
  name: "SplashPage",

  data() {
    return {
      scrollOptions: {
        duration: 500,
        offset: 0,
        easing: "easeInOutCubic",
      },
    };
  },
};
</script>

<style>
#gradient {
  background: linear-gradient(
    0deg,
    rgba(34, 41, 40, 0.5) 0%,
    rgba(1, 1, 1, 0) 50%
  );
}
</style>
