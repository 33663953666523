<template>
  <v-sheet color="background_dark">
    <v-img
      id="pricing"
      :src="require('@/assets/pricing.png')"
      class="py-16"
      fluid
      :max-height="$vuetify.breakpoint.lg ? 800 : 'initial'"
    >
      <v-container class="align-content-center fill-height">
        <v-row class="justify-center mb-10">
          <p class="text-h4 px-4 text-center white--text font-weight-bold">
            Get up and running real fast
          </p>
        </v-row>
        <v-row class="justify-center">
          <v-card
            class="ma-2 pa-3 d-flex flex-column"
            max-width="350px"
            min-height="500px"
          >
            <v-card-title class="justify-center font-weight-bold">
              Free Trial
            </v-card-title>

            <v-card-text
              class="text-h5 justify-center text-center black--text font-weight-bold"
            >
              <span class="text-h4 font-weight-medium">$0</span> for 30 days
            </v-card-text>

            <v-list>
            <v-list-item v-for="i in freeTier" :key="i" dense>
                <v-icon>mdi-circle-small</v-icon>
              {{ i }}
            </v-list-item>

            <v-list-item class="mt-4">
                <v-icon>mdi-circle-small</v-icon>
              Increase Customer satisfaction with status messaging and easy payment options
            </v-list-item>
            <v-list-item />
          </v-list>

            <!-- <v-chip label x-small>awaiting copywork</v-chip> -->

            <v-card-actions class="justify-center">
              <v-btn
                x-large
                color="primary"
                width="100%"
                href="https://amplifylawns.com/"
              >
                Get Started
              </v-btn>
            </v-card-actions>
          </v-card>

          <v-card
            class="ma-2 pa-3 d-flex flex-column"
            max-width="350px"
            min-height="550px"
          >
            <v-card-title class="justify-center font-weight-bold">
              All Features
            </v-card-title>

            <v-card-text
              class="text-h5 justify-center text-center black--text font-weight-bold"
            >
              <span class="text-h4 font-weight-medium">$9.99</span> /month
            </v-card-text>

            <v-list-item v-for="i in paidTier" :key="i">
                <v-icon>mdi-circle-small</v-icon>
              {{ i }}
            </v-list-item>

            <v-list-item class="mt-4">
                <v-icon>mdi-circle-small</v-icon>
              Increase Customer satisfaction with status messaging and easy payment options
            </v-list-item>
            <v-list-item />

            <v-card-actions class="justify-center">
              <v-btn
                x-large
                color="primary"
                width="100%"
                href="https://amplifylawns.com/"
              >
                Get Started
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-row>
        <v-row class="justify-center">
          <v-sheet dark color="transparent">
            <v-card-subtitle>
              With your Amplify subscription you get a Stripe Connect Express account.  Amplify pays the $2/month charge, and the Stripe processing fees are deducted from your payments.  More details <a href="https://stripe.com/connect" target="_blank">here</a>.
            </v-card-subtitle>
          </v-sheet>
        </v-row>
      </v-container>
    </v-img>
  </v-sheet>
</template>

<script>
export default {
  name: "pricingPage",
  data: () => ({
    freeTier: [
      "Get all the features free for 30 days",
      "Organize your lawn business",
      "Collect your $$ faster",
      "Communicate with Customers",
      "Access to in-app Support Chat",
    ],
    paidTier: [
      "Organize your lawn business",
      "Collect your $$ faster",
      "Communicate with Customers",
      "Access to in-app Support Chat",
    ],
  }),
};
</script>
